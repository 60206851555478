<template>
   <section id="page-visitor">
      <v-app-bar v-if="typeof activity.id === 'string' || activity.id >= 0" dark flat elevation="0" color="primary" height="68">
         <!-- v-if="activity.step > 0" -->
         <v-spacer />
         <v-toolbar-title class="text-title-1 font-weight-bold text-uppercase mx-1" style="padding-left: 44px;">
            Visit Registration
         </v-toolbar-title>
         <v-spacer />
         <v-menu transition="slide-x-reverse-transition">
            <template v-slot:activator="{ on, attrs }">
               <v-btn icon large v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
               </v-btn>
            </template>
            <v-list>
               <template v-for="(menu, idx) in menus">
                  <v-divider v-if="menu.divider" :key="`divider${idx}`" inset class="mx-0" style="max-width: 100%;" />
                  <div v-else-if="menu.lang" :key="`lang${idx}`">
                     <v-subheader style="height: 36px; font-size: 1rem; padding: 4px 16px 0 16px; color: black;">
                        Language
                     </v-subheader>
                     <v-radio-group v-model="activity.language" hide-details dense class="mt-0 mb-3 mx-3">
                        <v-radio v-for="lang in menu.lang" :key="lang" :label="lang" :value="lang" disabled />
                     </v-radio-group>
                  </div>
                  <v-list-item v-else :key="idx" :disabled="menu.disabled || (menu.value === 'first' && !badges.length) || (menu.value === 'visit' && activity.step === 1)"
                     @click="command(menu.value)"
                     >
                     <v-list-item-title>{{ menu.text }}</v-list-item-title>
                     <v-list-item-icon class="my-3">
                        <v-icon>{{ menu.icon || 'mdi-help' }}</v-icon>
                     </v-list-item-icon>
                  </v-list-item>
               </template>
            </v-list>
         </v-menu>
      </v-app-bar>
      <n-registration-form :activity="activity" :badges="badges" :organizations="organizations" :floors="floors" :reasons="reasons" :uploads="uploadBase64" @select="selectRecord" @update="updateRecord" />
   </section>
</template>

<script>
import { flatten, encrypt, decrypt } from '@/utils/nirtara'
import { approval } from '@/utils/fields.js'
const empty = { step: 0, id: -1, activity: '', language: 'English' }
export default {
   name: 'SectionPageVisitor',

   components: {
      NRegistrationForm: () => import('@/components/visitor/NRegistrationForm'),
   },

   data: () => ({
      isDebugging: false,
      isUsed: false,
      activity: Object.assign({}, empty), // , { activity: null }
      badges: [],
      organizations: [],
      floors: [],
      reasons: [],
      menus: [],
   }),

   watch: {
   },
   created () {
      this.isDebugging && console.log(`created ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
      this.$waitSetting(zone => {
         this.$socket.on(`client/${this.$store.state.zone}/Visitor`, this.handleRecord)

         this.isDebugging && console.log(`$waitSetting ( PageVisitor.vue => this.badges.length = ${this.badges.length} )`, JSON.parse(JSON.stringify(this.badges)))
         setTimeout(() => {
            // first time on chrome, watch not responded, then add 200 ms solved
            !this.badges.length && this.activity.activity !== 'Enrolled' && Object.assign(this.activity, { id: 0, activity: '' })
            this.isDebugging && console.log(`$waitSetting ( PageVisitor.vue => zone = ${zone} )`, JSON.parse(JSON.stringify(this.activity)))
         }, 400) // 200 -> sometimes not working, 300 -> sometimes not working

         this.selectTenant().then(result => {
            this.organizations = result.map(record => {
               const floors = record.Subject[0][record.kind].filter(j => j.Floor)
               const receptionists = record.Subject[0][record.kind].filter(j => j.Receptionist)
               record.Subject[0][record.kind] = record.Subject[0][record.kind].filter(j => !(j.Floor || j.Receptionist))
               return Object.assign({}, flatten(record), { Floors: floors, Receptionists: receptionists })
            })
         })
         this.selectParent({ kind: 'Floor', zone: this.$store.state.zone }, { _id: 0, identity: 1, name: '$object.name' }, 'Location').then(result => {
            this.floors = result
         })
      })

      this.menus = [
         { text: 'Check Status', icon: 'mdi-shield-check-outline', value: 'first' }, // , disabled: badges.length < 2
         { divider: true },
         { text: 'New Visit', icon: 'mdi-square-edit-outline', value: 'visit' },
      ]
      if (this.isDebugging) {
         this.menus.push(...[
            { divider: true },
            { lang: ['English', 'Indonesian'] },
            { divider: true },
            { text: 'Waiting ....', icon: 'mdi-timer-sand', value: 'reset' },
            { text: 'Last Step', icon: 'mdi-flag-checkered', value: 'final' },
            { divider: true },
            { text: 'Clear Cache', icon: 'mdi-broom', value: 'clear' },
         ])
      }
      // console.log(`created ( ${`client/${this.$store.state.zone}/Visitor`} )`)
      // this.$socket.on(`client/${ZONE}/Visitor`, this.handleRecord)
      // 1.0 ZONE, when mounted (localStorage & $route.params.email)
      // 2.0 has localStorage and has $route.params.email
      // 3.0 ZONE, after submit
      // 4.0 when checking domain, ZONE found!!!
      // this.isOffice8 = location.hostname.indexOf('office8.co.id') !== -1
      // const tld = location.hostname.replace(/^[^.]+\./g, '')
      // console.log(`created ( tld = ${'nvm.nirtara.com'.replace(/^[^.]+\./g, '')} )`)
      // console.log(`created ( tld = ${'vms.office8.co.id'.replace(/^[^.]+\./g, '')} )`)
      // this.selectParent(
      //    { kind: 'Management', site: this.$site, $or: [{ 'object.domain': { $regex: `.*${tld}` } }, { 'object.domain': 'nvm.nirtara.com' }] },
      //    { _id: 0, zone: 1, name: '$object.name', domain: '$object.domain' },
      //    'Option',
      // ).then(result => {
      //    // console.log(`created ( result = ${result.length} )`, JSON.parse(JSON.stringify(result)))
      //    this.zone = (result.find(j => j.domain.includes(tld)) || { zone: decrypt('MTAx') }).zone
      //    this.$socket.on(`client/${this.zone}/Visitor`, this.handleRecord)
      //    // console.log(`created ( ${`client/${this.zone}/Visitor`} )`)
      // })
   },
   async mounted () {
      this.isDebugging && console.log(`mounted ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
      // this.organizations = await this.selectParent({ kind: 'Organization' }, { _id: 0, identity: 1, name: '$object.name', Floors: '$array' }, 'Subject')
      // this.floors = await this.selectParent({ kind: 'Floor' }, { _id: 0, identity: 1, name: '$object.name' }, 'Location')

      this.badges = JSON.parse(decrypt(localStorage.getItem('nvm50dat') || encrypt('[]')))
      this.isDebugging && console.log(`mounted ( PageVisitor.vue => this.badges.length = ${this.badges.length} )`, JSON.parse(JSON.stringify(this.badges)))

      if (this.$route.params.email) {
         this.isDebugging && console.log(`mounted ( PageVisitor.vue => email = ${decrypt(this.$route.params.email)} )`)
         // this.activity.step = 1
         // /visitor/invitation/:email -> /visitor/invitation/amVzc2ljYS5uaXJ0YXJhQGdtYWlsLmNvbQ => { email: 'jessica.nirtara@gmail.com' }
         // console.log(`mounted ( ${location.pathname}, email = ${decrypt(this.$route.params.email)} )`, JSON.parse(JSON.stringify(this.$route.params)))

         this.selectParent(
            { kind: 'Approval', 'object.activity': { $in: ['Enrolled', 'Accepted'] }, 'object.email': decrypt(this.$route.params.email) },
            { },
            'Activity',
         ).then(result => {
            this.isDebugging && console.log(`mounted ( PageVisitor.vue => result.length = ${result.length} )`, JSON.parse(JSON.stringify(result)))
            const record = result.find(j => j.Activity[0].activity === 'Enrolled')
            if (record) {
               let accepted = (result.find(j => j.Activity[0].activity === 'Accepted') || { Activity: [{}] }).Activity[0]
               accepted.name && (accepted = { name: accepted.name, phone: accepted.phone })
               // console.log(`this.selectParent ( ${result.length} )`, JSON.parse(JSON.stringify(result[0])))
               // const record = result[0]
               const activities = record.Activity[0][record.kind].filter(j => j.Activity)
               record.Activity[0][record.kind] = record.Activity[0][record.kind].filter(j => !j.Activity)
               Object.assign(this.activity, approval, flatten(record), { Activities: activities }, accepted)
               // console.log('this.selectParent (this.activity)', JSON.parse(JSON.stringify(this.activity)))
               this.isDebugging && console.log(`mounted ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
            } else {
               // console.log('this.selectParent (result)', JSON.parse(JSON.stringify(result)))
            }
         })
      } else {
         const count = this.badges.length
         if (count) {
            const now = new Date()
            this.badges = this.badges.filter(j => this.$moment(j.since).isSame(now, 'day') || (j.until && this.$moment(j.until).isSameOrAfter(now, 'day')))
            if (this.badges.length) {
               this.isDebugging && console.log(`mounted ( PageVisitor.vue => this.badges.length !== count is ${this.badges.length !== count} )`, JSON.parse(JSON.stringify(this.badges)))
               this.badges.length !== count && localStorage.setItem('nvm50dat', encrypt(JSON.stringify(this.badges)))
               // if (this.badges.length === 1) {
               //    const badge = this.badges[0]
               //    setTimeout(() => {
               //    // this.$nextTick(() => {
               //       Object.assign(this.activity, {
               //          id: badge.id,
               //          activity: badge.status,
               //          identity: decrypt(badge.identity),
               //          organization: badge.organization,
               //          valid: badge.valid,
               //          until: badge.until,
               //       })
               //       console.log(`mounted ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
               //    // })
               //    }, 300)
               // }
            } else {
               localStorage.removeItem('nvm50dat')
            }
            // switch (this.badges.length) {
            //    case 0:
            //       localStorage.removeItem('nvm50dat')
            //       break
            //    case 1: // this.activity.step = 2 // 0 // 2
            //       // this.activity.step = this.badges[0].status === 'Register' ? 2 : 3 // Accepted | Rejected
            //       this.activity.identity = decrypt(this.badges[0].identity)
            //       this.activity.organization = this.badges[0].organization
            //       this.activity.activity = this.badges[0].status
            //       // this.activity.valid = this.badges[0].valid
            //       // this.activity.until = this.badges[0].until
            //       // this.$nextTick(() => {})
            //       break
            //    default: // this.activity.step = 0
            //       break
            // }
            // console.log(`mounted ( PageVisitor.vue => this.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
         } else {
            // this.activity.step = 1
            // fill activity with sample, delete later
            // this.$nextTick(() => {
            // setTimeout(() => {
            //    Object.assign(this.activity, approval, { id: 0, activity: '' }, this.isDebugging ? { name: '11', phone: '22', email: '33@33.33', reason: '44' } : null)
            //    console.log('mounted (sample)', JSON.parse(JSON.stringify(this.activity)))
            // }, 100)
            // })
            // this.$nextTick(() => {
            //    Object.assign(this.activity, { id: 0, activity: '' })
            //    console.log(`mounted ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
            // })
         }
      }
   },
   destroyed () {
      this.isDebugging && console.log('destroyed ( PageVisitor.vue )')
      this.$socket.off(`client/${this.$store.state.zone}/Visitor`, this.handleRecord)
   },
   methods: {
      command (menu) {
         this.isDebugging && console.log(`command ( PageVisitor.vue => menu = ${menu} )`, JSON.parse(JSON.stringify(this.activity)))
         switch (menu) {
            case 'first': // this.activity.step = 0
               Object.assign(this.activity, { id: -1, activity: '' }) // step: 0,
               // this.activity.kind = ''
               // this.selectStatus()
               break
            case 'visit': // this.activity.step = 1
               // Object.assign(this.activity, { step: 1, id: 0, activity: '', identity: '', organization: '', floor: '', reason: '' })
               // Object.assign(this.activity, approval, empty, { step: 1 })
               // console.log('command (activity)', JSON.parse(JSON.stringify(this.activity)))
               // this.selectParent(
               //    { kind: 'Approval', 'object.activity': this.activity.activity, identity: this.activity.identity },
               //    { _id: 0, name: '$object.name', phone: '$object.phone', email: '$object.email' },
               //    'Activity',
               // ).then(result => {
               //    console.log(`command ( activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(result)))
               //    result.length && Object.assign(this.activity, result[0], { step: 1, id: 0, activity: '', identity: '', organization: '', floor: '', reason: '' })
               // })
               Object.assign(this.activity, { id: 0, activity: '' })
               this.isDebugging && console.log(`command ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
               break
            case 'reset': this.activity.step = 2
               break
            case 'final': this.activity.step = 3
               break
            case 'clear': localStorage.removeItem('nvm50dat')
               break
         }
      },
      async selectRecord (object) {
         this.isDebugging && console.log(`selectRecord ( PageVisitor.vue => identity = ${object.identity} )`, JSON.parse(JSON.stringify(this.activity)))
         const result = await this.selectParent(
            { kind: 'Approval', zone: this.$store.state.zone, identity: decrypt(object.identity) },
            { },
            'Activity',
         )
         // console.log('selectRecord (result)', JSON.parse(JSON.stringify(result)))
         if (result.length) {
            const record = result[0]
            const activities = record.Activity[0][record.kind].filter(j => j.Activity)
            record.Activity[0][record.kind] = record.Activity[0][record.kind].filter(j => !j.Activity)
            Object.assign(this.activity, approval, flatten(record), { Activities: activities })
            this.isDebugging && console.log(`selectRecord ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
            // console.log('selectRecord (this.activity)', JSON.parse(JSON.stringify(this.activity)))
         } else {
            this.$store.commit('snackNotification', { Message: [{ Error: [], note: 'Invalid Visitor ID' }], kind: 'Error' })
            const idx = this.badges.findIndex(j => j.identity === object.identity)
            this.badges[idx].disabled = true
            const nvm50dat = JSON.parse(JSON.stringify(this.badges))
            nvm50dat.splice(idx, 1)
            localStorage.setItem('nvm50dat', encrypt(JSON.stringify(nvm50dat)))
         }
      },
      selectParent (conditions, projection, type) { // , options
         this.isDebugging && console.log('selectParent ( PageVisitor.vue )')
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               // options: options ?? {},
               headers: { command: `select${type ?? 'Person'}` },
            }
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      selectTenant () {
         this.isDebugging && console.log('selectTenant ( PageVisitor.vue )')
         return new Promise((resolve) => {
            var data = {
               conditions: { kind: 'Organization', zone: this.$store.state.zone },
               headers: { command: 'tenantSubject' },
            }
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data)).catch(() => resolve([]))
         })
      },
      uploadBase64 (array, callback) {
         this.$store.dispatch('upload', array).then(response => callback(response.data))
      },
      async updateRecord (item) {
         this.isDebugging && console.log('updateRecord ( PageVisitor.vue )')
         const array = []
         item.Activities && array.push(...item.Activities)
         const images = []
         item.Card && images.push({ Card: [item.Card] }) // { Card: item.Card ? [`${item.Card.substr(0, 32)}....`] : [] },
         item.Face && images.push({ Face: [item.Face] }) // { Face: item.Face ? [`${item.Face.substr(0, 32)}....`] : [] },

         var data = {
            update: {
               id: typeof item.id === 'string' ? item.id : undefined,
               site: this.$site,
               kind: item.kind,
               zone: this.$store.state.zone,
               identity: item.identity || '*',
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name, // visitor name
                  // below added for approval
                  activity: item.activity,
                  phone: item.phone,
                  email: item.email,
                  organization: item.organization,
                  floor: item.floor,
                  reason: item.reason,
                  message: item.message,
                  valid: item.valid,
                  until: item.until,
                  // below added for invitation
               },
            },
            headers: { command: item.kind === 'Approval' ? 'updateApproval' : item.kind === 'Invitation' ? 'updateInvitation' : 'updateActivity' },
         }
         // console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Activity) {
            // Object.assign(this.activity, { id: response.data.id, identity: response.data.identity }) // , step: 2
            // console.log(`updateRecord ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
            // this.activity.id = response.data.id
            // this.activity.identity = response.data.identity
            // this.activity.step = 2
            // console.log('updateRecord (this.activity)', JSON.parse(JSON.stringify(this.activity)))

            const json = response.data
            const visitor = {
               Visitor: [],
               id: json.id,
               identity: encrypt(json.identity),
               status: json.Activity[0].activity,
               since: json.createdAt,
               organization: json.Activity[0].organization,
               valid: json.Activity[0].valid,
               until: json.Activity[0].until,
            }
            this.isDebugging && console.log(`updateRecord ( PageVisitor.vue => visitor.status = ${visitor.status} )`, JSON.parse(JSON.stringify(visitor)))
            this.badges.unshift(visitor)
         }
      },
      handleRecord (item) {
         // console.log('handleRecord ( PageVisitor.vue )')
         item.Content.forEach(async record => {
            // console.log(`handleRecord ( PageVisitor.vue => record.id = ${record.id} )`, JSON.parse(JSON.stringify(record)))
            const badge = this.badges.find(j => j.id === record.id)
            if (!badge) return
            badge.status = record.Activity[0].activity
            badge.valid = record.Activity[0].valid
            badge.until = record.Activity[0].until
            // console.log(`handleRecord ( PageVisitor.vue => badge.status = ${badge.status} )`, JSON.parse(JSON.stringify(badge)))

            const activities = record.Activity[0][record.kind].filter(j => j.Activity)
            record.Activity[0][record.kind] = record.Activity[0][record.kind].filter(j => !j.Activity)
            Object.assign(this.activity, approval, flatten(record), { Activities: activities })
            // console.log('handleRecord (this.activity)', JSON.parse(JSON.stringify(this.activity)))
            this.isDebugging && console.log(`handleRecord ( PageVisitor.vue => this.activity.id = ${this.activity.id}, this.activity.activity = ${this.activity.activity} )`, JSON.parse(JSON.stringify(this.activity)))
         })
         localStorage.setItem('nvm50dat', encrypt(JSON.stringify(this.badges)))
      },
   },
}
</script>
